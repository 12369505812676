<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="letters__hero">
					<h1>Mike<br>Bodson</h1>
					<p>A Message from the CEO & President</p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="mike-body">
					<div class="letters__body">
						<h2>How do you summarize <br> <span>a year like 2020?</span></h2>

						<div class="body-columns">
							<div class="body-columns__content">
								<p>The right way to start is by honoring the more than 2.6 million people globally, including several members of our DTCC family, who have died so far in the COVID-19 pandemic. It’s hard to fathom the pain, suffering and loss of life that have occurred in little more than one year. Our thoughts and prayers remain with the victims and their families during this very difficult time. </p>

								<p>Next, we must thank the men and women on the front lines: healthcare workers, first responders, teachers, military, cashiers, stock people, shop owners, janitors, restaurant workers and truck drivers, among others. They are true heroes who risked their own health and safety to keep the world moving forward. Their courage is inspiring.</p>

								<p>I also want to pay special recognition to DTCC’s own front-line employees — roughly 300 dedicated colleagues in Global Business Operations, Global Security Management, End User Support, Information Technology and Workplace Design and Services who have continued to perform essential functions on site to help protect the safety and stability of the global financial system. On behalf of our Board of Directors, Management Committee and fellow employees, thank you for your commitment to our firm and the industry.</p>

								<p>As I look back over the year, I cannot recall another time when DTCC was forced to confront such a wide range of issues over an extended period of time. For all the challenges we faced in the wake of the devastation of the September 11 terrorist attacks in 2001 and Superstorm Sandy in 2012, the COVID-19 pandemic tested our resolve in unique ways. Finding the right answer or determining the best course of action was not always easy, but our ability to seamlessly serve our clients, prioritize the well-being of our employees and maintain focus on executing our strategy is the result of the trust our leadership team places in one another, our ability as an organization to collaborate across areas and our commitment to empowering teams to respond decisively in a rapidly changing environment. </p>
							</div>

							<div class="body-columns__images">
								<img src="~@/assets/letters/bodsonImg1@2x.png" width="361" />

								<img src="~@/assets/letters/bodsonImg2@2x.png" width="361" />
							</div>
						</div>

					<div class="mike-quote"><blockquote>As we have always done when confronted with intractable problems, we turned to our values and asked ourselves a simple question: “What is the right thing to do?”</blockquote></div>

						<div class="body-columns--small">
							<h2><span>Seamlessly Managing</span><br> Volatility and Volume</h2>

							<p>The right thing to do when the pandemic erupted in mid-March was to shift nearly 5,000 of our employees — about 95% of all staff — to remote work so they could be safe while continuing to support the industry. An action of this scale had never been attempted before in the history of DTCC, but we had confidence in our actions because we had stress-tested crisis scenarios — including pandemics — and validated our technology and built superior organizational agility. On March 17, the first day most of our workforce began working remotely, our colleagues rose to the occasion, processing 113.6 billion shares worth $2.54 trillion, roughly 49.5% above the average daily value of transactions we managed in 2020.</p>

							<p>As the volatile markets continued to peak and plummet, our team quickly settled into a steady rhythm and handled the extreme trading volumes with the same precision that has defined DTCC for nearly five decades. Throughout this, we worked collaboratively with our clients to manage risk in response to volatile markets, maintained a continual dialogue with our regulators and kept in close contact with firms experiencing stress. </p>

							<p>Overall, we set several new volume records in 2020, including processing $2.329 quadrillion in securities transactions, up nearly 8% over the previous year. In addition, we hit a new, single-day mark of 363 million U.S. equity transactions on March 12 — more than two-and-a-half times that of an average processing day — without issue. This new peak is more than 15% higher than our last one of 315 million transactions in October 2008, at the height of the financial crisis. Similarly, our Trade Information Warehouse managed more credit events in the first half of 2020 than we did in the previous full year, including the bankruptcy of the McClatchy Company, which triggered a $6.9 billion gross payout on counterparties’ default insurance on the firm’s debt. We also coordinated a firm default, closing out the entity while working virtually for the first time in our history.</p>
						</div>

						<div class="mike-quote"><blockquote>How did we do all this? The truth is, DTCC us built for these moments. For nearly 50 years, we have protected the industry, ensured market stability and provided certainty and stability when markets were at their most vulnerable.</blockquote></div>


						<div class="body-columns body-columns--reverse">

							<div class="body-columns__content">
								<h2 style="margin-top: 0;">Risk <span>and Resilience</span></h2>
								<p>A critical element of our ability to serve clients and ensure the stability of the markets is our comprehensive risk management framework. In 2020, those efforts ranged from enhanced monitoring of targeted clients and sectors to increased outreach to clients on processes and tools to determine margin requirements amid intense volatility. In addition, our business continuity and resilience teams quickly developed and executed plans for remote working as well as performed research, engaged with health experts and coordinated activities to prepare for our eventual return to our office when it is safe.</p>
							</div>

							<div class="body-columns__images">
								<img src="~@/assets/letters/bodsonImg3@2x.jpg" width="360" />
							</div>
						</div>


						<div class="body-columns--small">

							<p>Across all disciplines of risk management, we advanced key priorities to protect the marketplace, including making enhancements to risk portals and margin calculators, providing additional disclosure to bring greater transparency to our methodologies and algorithms and, in an exciting development, launching the Risk Management As A Service application programming interface (API) to provide clients with direct access to our systems. </p>

							<p>Operational resilience in the face of cyber and other threats continues to be a focal point of supervisory, regulatory and international standards-setting communities. We remain deeply committed to working with regulatory agencies, our industry peers and trade associations to improve resilience. In 2020, we continued our engagement in public/private forums to develop appropriate practices and policies that will strengthen the readiness of the financial services sector and improve the resilience of the industry. As part of this effort, we remain deeply committed to partnering with organizations like the Financial Services Sector Coordinating Council to strengthen our preparedness against cyber-attack. Toward that end, we adopted the Council’s Cybersecurity Profile last year to support our compliance process with cybersecurity rules and guidance provided by global authorities.</p>
							<p><br></p>
						</div>

						<h2>An Unprecedented Year Yields <br><span>Solid Performance</span></h2>

						<div class="body-columns">
							<div class="body-columns__content">

								<p>By the end of 2020, the extraordinary trading activity produced one of our best financial performances ever, and thanks to work we have undertaken over the past several years, our financial position has never been more solid. In 2020, we had record revenues of $1.8 billion, which enabled our firm to issue client rebates for the second straight year and accelerate key strategic investments. In a sign of our financial stability and strength, we issued two debt offerings virtually, another first for us, raising $3.75 billion for prefunded default liquidity resources for our subsidiary, National Securities Clearing Corporation.</p>

								<p>While the pandemic was the headline event of 2020, we continued to maintain a steady focus on solving the business challenges facing our clients and the industry. For instance, we grew our Fixed Income Clearing Corporation’s Sponsored Membership service, which enables clients to use a centrally cleared platform for their repo trading activity to reduce counterparty risk and provide balance sheet and capital relief. NSCC average daily trading volume was 112.5 billion shares, an increase of nearly 50% year-over-year with a worth of $1.7 trillion, a rise of 43% compared to 2019.</p>

								<p>In addition, we completed a key initiative to optimize the U.S. equity settlement system by re-engineering night cycle processing, which has improved intraday settlement finality and delivered substantial savings through lower transaction costs. We see this as an important step as we work to galvanize the industry to support accelerating settlement to T+1 or T+&frac12; by 2023. </p>

								<p>We took further aim at modernizing the settlement system by unveiling plans to dematerialize the U.S. markets — a priority that took on even greater urgency during the pandemic when teams across the industry were forced to remain on site to process certificates to keep the system running. Our white paper, From Physical to Digital: Advancing the Dematerialization of U.S. Securities, which we issued in September, outlines our plans to achieve full dematerialization within the next four years.</p>

								<p>While these efforts address more immediate matters, the ability to see beyond what is in front of us today and completely reimagine core post-trade functions underpins two initiatives we launched in 2020: Projects Ion and Whitney. The former explores accelerating settlement to T+1 and T+0 on a digital platform using distributed ledger technology (DLT) and other emerging technologies, while the latter considers the potential for asset tokenization and digital infrastructure to support private market securities. We believe both projects have the potential to change the future of our industry.</p>

								<p>Our solution to support compliance with Securities Financing Transaction Regulation (SFTR) in Europe continues to be a case study in teamwork and success as more than 500 dealers, agent lenders and buy-side firms subscribed, nearly doubling our projections. In fact, our work with SFTR led to an exciting opportunity for us to expand DTCC Report Hub, a service we launched in 2019, by acquiring Publicis Sapient’s Compliance Management Reporting System (CMRS). By integrating CMRS into Report Hub, we were able to achieve our long-term vision and meet our clients’ needs immediately by delivering a unified, single-vendor platform for managing derivatives, Markets in Financial Instruments II (MiFID II), and SFTR pre- and post-trade reporting requirements. </p>

								<p>We will continue to explore ways to bring our solutions to market as quickly as possible, just as we will remain focused on developing our businesses, including building community for Margin Transit Utility, launching new products in Data Services and growing our new Consulting Services, all to better serve our clients.</p>
							</div>

							<div class="body-columns__images">
								<a href="https://perspectives.dtcc.com/articles/leading-the-industry-to-accelerated-settlement" target="_blank"><img src="~@/assets/letters/mikepaper1@2x.jpg" width="266" /></a>

								<a href="https://perspectives.dtcc.com/articles/dematerialization-of-us-securities" target="_blank"><img src="~@/assets/letters/mikepaper2@2x.jpg" width="266" /></a>

								<a href="https://perspectives.dtcc.com/articles/project-ion" target="_blank"><img src="~@/assets/letters/mikepaper3@2x.jpg" width="266" /></a>

								<a href="https://perspectives.dtcc.com/articles/project-whitney" target="_blank"><img src="~@/assets/letters/mikepaper4@2x.jpg" width="266" /></a>
							</div>
						</div>

						<div class="body-columns--small">
							<h2>Our Transformation <br /><span>Strategy</span></h2>

							<p>One of the biggest challenges that organizations face when confronted with a long-term crisis like a pandemic is balancing their short-term, tactical response with the ability to remain focused on executing their strategy. At DTCC, we have years of experience in shaping the future of the global markets, knowing it requires seeing well beyond today’s environment, exploring exciting new possibilities, reimagining what the future could be and being bold enough to lead the journey.</p>

							<p>We are well-suited to this task because of our unique role in protecting the financial system and investors every day while developing new products, driving innovation and promoting new ideas to solve business challenges.</p>

							<p>In many ways, the pandemic intensified a series of market and external dynamics that have been building for several years. Our industry continues to see increased cost pressure and disruption of incumbent firms, along with greater buy-side influence and the growth of private markets and digital assets. Clients are very clear about their priorities as well, calling for increased resilience, elimination of manual workflows, lower costs and more input in developing new tools and services. Clients are also placing a premium on their need for open access to data and services — our API marketplace is an example of how we have responded to that — and they do not want to wait until a “perfect” solution is developed. They’ll take an incremental one that serves their needs now, and they want to help shape the next iteration.</p>

							<p>Our teams made significant progress in 2020, establishing frameworks for managing cross-functional projects, with prioritization based on industry and client needs. </p>

							<p>Following a “build it once and reuse it again and again” philosophy, we began developing secure, resilient, reusable assets to create modernized products and services. In the coming years, these efforts will deliver added value to the industry via a new equity clearing and settlement and integrated asset services platform, simplified matching engines, refined data structure and “golden source” data store, a wide assortment of APIs in our expanding marketplace and advanced cyber-resilience vulnerability detection and response tools. Importantly, each of these component pieces will help build our larger vision to transform the firm and drive forward initiatives that we are leading for the industry.</p>
						</div>
					</div>

					<div class="mike-breakout">
						<div class="letters__body">
							<div class="body-columns--small">
								<h2>How We Are Reimagining and Modernizing Our Business Capabilities & Technology.</h2>
								<p>We recognize that accommodating our clients in a rapidly moving environment requires a significant transformation of our firm in the coming years, as we refine our strategic roadmap and focus on four key priorities to:<br><br></p>
							</div>


							<boxes>
								<template v-slot:box1>
									<h3><img src="~@/assets/letters/mikeBoxIcon1.svg" > Protect the Capital Markets</h3>
									<p>Strengthen DTCC and our industry through leading-edge risk management with resilient technology and processes.</p>
								</template>

								<template v-slot:box2>
									<h3><img src="~@/assets/letters/mikeBoxIcon1.svg" > Transform the Post-Trade Ecosystem</h3>
									<p>Shape the future of post-trade by reimagining industry infrastructure and practices to reduce risk and cost for both DTCC and our clients.</p>
								</template>

								<template v-slot:box3>
									<h3><img src="~@/assets/letters/mikeBoxIcon1.svg" > Rearchitect Our Services</h3>
									<p>Deliver a modern platform focused on client value, simplification, and operational stability.</p>
								</template>

								<template v-slot:box4>
									<h3><img src="~@/assets/letters/mikeBoxIcon1.svg" > Elevate the Client Experience</h3>
									<p>Provide our clients with flawless daily execution, automated digital interaction, and superior service.</p>
								</template>
							</boxes>
						</div>
					</div>

					<div class="letters__body">

						<div class="body-columns body-columns--reverse">
							<div class="body-columns__content">

								<h2 style="margin-top: 0;"><span>Honest, Open Discussions</span> <br> about Race</h2>

								<p>Like many firms in this extraordinary year, world events prompted DTCC to look inward and open a new dialogue about racial equality following the tragic deaths of George Floyd, Breonna Taylor and Ahmaud Arbery in the U.S. Rather than shy away from the debate, we addressed the issues head-on, holding frank, honest — and sometimes uncomfortable — discussions about these issues. We encouraged dialogue among our colleagues and organized a firm-wide “Perspectives” series with black and brown employees who shared their experiences, fears and frustrations. We believe organizations have a responsibility to the community at large to increase awareness and educate one another about issues like unconscious bias, equality and fairness. <br> <br> </p>

							</div>

							<div class="body-columns__images">
								<img src="~@/assets/letters/bodsonImg4@2x.jpg" width="361" />
							</div>
						</div>


						<div class="body-columns--small">
							<p>In that same vein, we remain deeply committed to the progress of our diversity and inclusion programs as we recognize that our workforce must reflect the communities where we live and work. In 2020, we increased the number of women promoted to Officer and Associate Director level, and while we are proud of this progress, our work is really still just beginning. Toward that end, we introduced several new programs in 2020 that reflect our commitment to being a diverse and inclusive organization, including Catalyst’s Men Advocating for Real Change workshop for Officers, the Advancing Women Leaders program for Directors and the Re-Emerge: Return to Work program to recruit high-caliber individuals who want to return to the workplace following a career break. </p>
						</div>

						<div class="mike-columns">
							<div>
								<img src="~@/assets/letters/mikeAwards1@2x.jpg" width="152" />
								<p>For the ninth consecutive year, the Human Rights Campaign named DTCC as one of the Best Places to Work for LGBTQ Equality.</p>
							</div>
							<div>
								<img src="~@/assets/letters/mikeAwards2@2x.jpg" width="152" />
								<p>Forbes listed us in its independent rankings for Best Places for Diversity 2020.</p>
							</div>
							<div>
								<img src="~@/assets/letters/mikeAwards3@2x.jpg" width="152" />
								<p>Fairygodboss named us the Best Finance Company for Women.</p>
							</div>
							<div>
								<img src="~@/assets/letters/mikeAwards4@2x.jpg" width="152" />
								<p>In Tampa, Feeding Tampa Bay awarded DTCC its first Helping Hands Award for our help with COVID-19 relief efforts.</p>
							</div>
						</div>

						<div class="body-columns--small">
							<h2>Remembering <span>Friends</span></h2>
							<p>Our colleagues have always been the key to DTCC’s success. In January 2021, our family experienced deep loss with the deaths of four former executives, each of whom played a vital role in shaping our firm: Bill Dentzer, the founding CEO of DTC from 1973 to 1994; Bill Jaenike, the CEO of the DTC from 1994 to 1999; Dave Kelly, President and CEO of National Securities Clearing Corporation from 1983 to 1999; and Ellen Fine Levine, who spent 20 years at DTCC in senior roles, including Chief Financial Officer. These four dear colleagues each played a critical role in creating and enhancing the modern post-trade model that we know today. Their vision, combined with superb management skills and the ability to inspire the people they worked with, helped to establish the DTCC culture that endures to this day. We mourn their loss and miss them greatly.</p>
						</div>

						<div class="mike-columns mike-columns--center">
							<div>
								[circle]
								<p>
									<strong>Bill Dentzer</strong><br>
									<small>Founding CEO of DTC <br> 1973-1994</small>
								</p>
							</div>
							<div>
								[circle]
								<p>
									<strong>Bill Jaenike</strong><br>
									<small>CEO of the DTC <br> 1994-1999</small>
								</p>
							</div>
							<div>
								[circle]
								<p>
									<strong>Dave Kelly</strong><br>
									<small>President and CEO of National Securities Clearing Corporation, 1983-1999</small>
								</p>
							</div>
							<div>
								[circle]
								<p>
									<strong>Ellen Fine Levine</strong><br>
									<small>Chief Financial Officer</small>
								</p>
							</div>
						</div>

						<div class="body-columns--small">
							<h2>A <span>True</span> Collaboration</h2>

							<p>I’m always very grateful for my relationship with our Board of Directors. Our unique role as a market infrastructure means that we serve the industry and try to lead it as well, and I appreciate the insight and guidance they provided as we navigated the pandemic and market turmoil together. I give special thanks to Bob Druskin, our non-Executive Chairman, for his many years of friendship and counsel.</p>

							<p>I also offer thanks to our Management Committee, which handled each challenge with the diligence, expertise and fortitude that have long distinguished this group. Our executives have the gift of making it look easy even when it most definitely is not. The same can be said for our many colleagues around the world, whose extraordinary work under the most difficult conditions imaginable enabled us to fulfill our mission to protect the safety and stability of the global financial markets. On behalf of the Board and Management Committee, we thank you all once again.</p>
						</div>

						<div class="mike-quote"><blockquote>The post-pandemic world will be a time of intense change, but as I look to the future, I sense a growing optimism and confidence. </blockquote></div>

						<div class="body-columns--small">
							<p>We embrace our role as architects of innovation, knowing that every action we take must ensure a better experience for our clients and a better future for our industry. We are confident in our ability to meet that challenge. The world always will be unpredictable, and we cannot ever truly know what lies around the corner, but as we proved in 2020, as a team we can manage any situation, overcome every challenge and continue to excel. I trust that we will emerge from this period wiser, with a renewed sense of purpose and clarity of vision, and be inspired to seize the opportunities of better days ahead.</p>

							<p>On behalf of DTCC, I wish you health, safety and a brighter tomorrow. We look forward to working with all our stakeholders in 2021 and beyond.</p>

							<p>
								<img src="~@/assets/letters/mikesig@2x.jpg" width="315" /><br>
								<strong>Mike Bodson</strong><br>
								<em>President & CEO</em>
							</p>
						</div>
					</div>
				</div>
			</template>

			<template v-slot:footer>

				<article-next class="csr__next" :image="'letters/bg-robert@2x.jpg'" :link="{name: 'letters', params: {
					letter: 'robert'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>OUR LEADERSHIP</template>
					<template v-slot:title>Robert Druskin</template>
				</article-next>

			</template>

		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'
import Boxes from '@/components/Boxes'

export default {
	name: 'Mike',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
		Boxes
	},
}
</script>

<style scoped lang="scss">

.letters {

	&__hero {
		background-image: url('~@/assets/letters/bgMike@2x.jpg');
	}

	p {
		@include media-query-min-width('desktop') {
			@include fontSize(22px);
			@include gutter('margin-bottom', 1.5);
			line-height: 1.5;
		}
	}

	.mike-body {
		background-image: url('~@/assets/letters/mikeBg@2x.jpg');
		background-size: 100%;
		background-position: center top;
		background-repeat: no-repeat;
	}

	.mike-quote {
		@include gutter('padding', 3);
		@include gutter('margin-top', 2);
		@include gutter('margin-bottom', 3);
		background-color: color_('primary', 'dark');
		background-image: url('~@/assets/letters/bgMikeQuote@2x.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 20px;

		@include media-query-min-width('desktop') {
			// @include gutter('margin-top', 2);
			// @include gutter('margin-bottom', 3);
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		blockquote {
			color: color_('primary', 'text');
			font-family: $font-family-secondary;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			margin-bottom: 0;

			@include media-query-min-width('desktop') {
				@include fontSize(40px);
			}
		}
	}

	.mike-columns {
		@include gutter('margin-top', 3);
		//@include gutter('margin-bottom', 3);
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		div {
			flex: 1;
			width: 100%;
			text-align: center;
			@include gutter('margin-bottom', 2);

			@include media-query-min-width('tablet') {
				text-align: initial;
				@include gutter('padding-left', 1);
				@include gutter('padding-right', 1);
			}

			img {
				margin-bottom: 10px;
			}

			&:first-of-type {
				@include gutter('padding-left', 0);
			}

			&:last-of-type {
				@include gutter('padding-right', 0);
			}

			small {
				color: color_('text', 'light');
				@include fontSize(14px);
				line-height: 1.5;
				display: inline-block;
			}

			strong {
				margin: 10px 0;
				display: inline-block;
			}
		}

		&--center div {
			text-align: center;
		}

		@include media-query-min-width('tablet') {
			flex-direction: row;
		}

		p {
			@include fontSize(16px);
			margin-bottom: 0;
		}
	}

	.mike-breakout {
		background-color: color_('primary', 'dark');
		background-image: url('~@/assets/letters/bgMikeBreakout@2x.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		.body-columns--small {
			color: color_('primary', 'text');
		}

		.letters__body {
			h2 {
				color: color_('primary', 'text');
			}
		}
	}
}

</style>
